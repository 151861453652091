import { cloneDeep, isEmpty, isEqual, throttle } from 'lodash';
import { BaseService } from './baseService';

export default class VideoPlayerService extends BaseService {
    /* @ngInject */
    constructor($http, EID, PID) {
        super($http);

        this.$http = $http;
        this.eventId = EID;
        this.pid = PID;

        this.errorBuffer = [];
        this.lastAddedError = null;

        const jitter = 3000 + Math.round(Math.random() * 3000);

        const sendErrorBuffer = async () => {
            if (!this.errorBuffer || this.errorBuffer.length === 0) {
                return;
            }

            const url = `/api/v1/eid/${this.eventId}/live-stream/log-pax-issues`;
            // If we fail to send the error buffer thrice, we can assume the errors were not related to the player,
            // so we can always clear the buffer
            this.postWithRetries(url, { errorArray: cloneDeep(this.errorBuffer), pid: this.pid }, 2, 1000);
            this.errorBuffer = [];
        };

        this._sendErrorBuffer = throttle(sendErrorBuffer, jitter, { leading: false, trailing: true });
    }

    /**
     * Post player issues to be logged by backend
     * @param {Object} payload
     * @return {void}
     */
    trackError(payload) {
        if (isEmpty(payload) || isEqual(payload, this.lastAddedError)) {
            return;
        }
        this.lastAddedError = payload;

        this.errorBuffer.push(payload);
        this._sendErrorBuffer();
    }

    /**
     * Get cookies for a given resource, webapp/signed-asset routes redirect to the proper domain to add the cookies,
     * then returns the resource's direct url with the proper cookies attached to the response
     * @param {String} url
     * @returns {Promise<String>}
     */
    async getCookies(url) {
        if (!url || !url.includes('webapp/signed-asset')) {
            return url;
        }

        const response = await this.$http.get(url, { withCredentials: true, cache: false, params: { skipRedirect: true } }).catch((err) =>
            console.error('[VideoPlayerService] webapp/signed-asset error', err)
        );

        if (!response || !response.data || !response.data.fileUrl) {
            console.error('[VideoPlayerService] failed to get fileUrl', { url, response });
            return url;
        }

        const { fileUrl } = response.data;

        console.log('[VideoPlayerService] getCookies response ', { fileUrl });

        return fileUrl;
    }
}
