/**
 *  This nav is used to create a new meeting.
 *  It is usually open in a new modal screen.
 *
 *  It collects all needed data, validates value and sends all to the server.
 */
import { get, clone, isNumber } from 'lodash';
import moment from 'moment-timezone';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

export const NewMeetingNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./new-meeting-nav.pug'),
    controller: class NewMeetingNavComponent {
        /* @ngInject */
        constructor(
            progressBarService,
            databaseService,
            navService,
            $scope,
            $sheet,
            $i18n,
            THEME,
            ACTIVATED_PERSON,
            EVENT,
            SETTINGS
        ) {
            this.progressBarService = progressBarService;
            this.databaseService = databaseService;
            this.navService = navService;
            this.$scope = $scope;
            this.$sheet = $sheet;
            this.$i18n = $i18n;
            this.theme = THEME;
            this.actPerson = ACTIVATED_PERSON;
            this.event = EVENT;
            this.settings = SETTINGS;
            this.isEditMode = false;
            this.validatedOnce = false;

            this.reset();
        }

        $onInit() {
            const config = this.config;
            console.log('[NewMeetingNavComponent] init', config);

            this.participants_ds = this.config.participants_list.ds;

            this.durations = [ this.duration ];
            config.durations_list.ds.source.forEach(d => {
                const enabled = get(this.settings, 'meetings.allowed-durations.' + d.duration, true);
                if (enabled) {
                    this.durations.push({ label: d.title, value: d.duration });
                }
            });

            this.datePickerConfig = {};

            let timeInterval = get(this.settings, 'meetings.time_interval');
            if (timeInterval) {
                // minuteStep must be greater than zero and less than 60
                timeInterval = Number.parseInt(timeInterval) / 60;
            } else {
                timeInterval = 30;
            }

            if (timeInterval < 60) {
                this.datePickerConfig['minuteStep'] = timeInterval;
            } else {
                this.datePickerConfig['minView'] = 'hour';
            }

            this.$scope.$watch('$ctrl.title', () => {
                if (this.validatedOnce) this.validate();
            });
            this.$scope.$watch('$ctrl.date', this.onDurationPaxOrDateChange.bind(this));
            this.$scope.$watch('$ctrl.duration', this.onDurationPaxOrDateChange.bind(this));
            this.$scope.$watch('$ctrl.participants', this.onDurationPaxOrDateChange.bind(this), true);
            this.$scope.$watch('$ctrl.showAllRooms', this.onRoomsVisibilityChange.bind(this));
            this.$scope.$watch('$ctrl.showAllTimeSlots', this.onTimeSlotVisibilityChange.bind(this));
            this.$scope.$watch('$ctrl.location', newVal => {
                if (this.validatedOnce) this.validate();
                if (this.meeting_location && (newVal || '').length) {
                    this.meeting_location = null;
                }
            });
            this.$scope.$watch('$ctrl.meeting_location', newVal => {
                if (this.validatedOnce) this.validate();
                if (this.location && newVal) {
                    this.location = '';
                }
            });

            this.participants = [];
            this.pax_suggestions = [];
            this.supportsBooking = 'get_available_locations' in this.config.actions;
            this.showCustomLocation = get(this.settings, 'meetings.custom-location', true);
            this.externalAllowed = get(this.settings, 'meetings.allow-external-invitees', false);

            this.showInternalNotes = config.meetings_v4;
            this.showDescription = this.showInternalNotes;
            this.showLegacyNotes = !this.showInternalNotes;

            const { meeting_data } = config;

            if (meeting_data) {
                this.isEditMode = true;

                this.title = meeting_data.title;
                this.participants = [
                    ...meeting_data.participants.filter(p => {
                        const paxId = p._id || p.id;
                        return paxId && paxId !== this.actPerson._id;
                    }),
                    ...(meeting_data.externals || []).map(e => ({ email: e }))
                ];
                this.meeting_location = meeting_data.meeting_location;
                this.location = meeting_data.location;

                this.showLocation = (this.location || this.meeting_location) || this.duration && this.date && this.participants.length;

                this.date = new Date(meeting_data.start_time * 1000);
                this.dateByEventTimezone = null;

                const notesField = this.showLegacyNotes ? 'notes' : 'internal_notes';

                this[notesField] = meeting_data[notesField] || '';
                this.description = meeting_data.description;
                this.originalPaxes = clone(meeting_data.participants);

                const { duration } = meeting_data;
                if (duration) {
                    this.duration = {
                        label: duration.title,
                        value: duration.duration
                    };
                }
            } else {
                let defaultStartTs;
                if (config.params?.startTs) {
                    defaultStartTs = config.params.startTs;
                } else {
                    defaultStartTs = get(this.settings, 'meetings.default_start_date', this.event.description.start_time);
                }
                const defaultStartDate = new Date(defaultStartTs * 1000);
                const now = new Date();
                const startDate = now > defaultStartDate ? now : defaultStartDate;

                const timeSteps = Number(get(this.settings, 'meetings.time_interval', '1800'));
                this.date = new Date(Math.ceil(startDate / 1000 / timeSteps) * timeSteps * 1000);
            }

            this.setHumanReadableDate(meeting_data || this.settings.date.timezone === 'device');

            this.listenToAddExternalParticipant = (e) => {
                this.participants.push(e.detail);
            };

            window.addEventListener('add-external-participant', this.listenToAddExternalParticipant);
        }

        $onDestroy() {
            console.info('[NewMeetingNavComponent] destroying');
            this.hideDatepicker();
            window.removeEventListener('add-external-participant', this.listenToAddExternalParticipant);
        }

        /**
         * Sets default meeting form values.
         */
        reset() {
            this.title = '';
            this.participants = [];

            this.rooms = [];
            this.visibleRooms = [];
            this.showAllRooms = false;
            this.showMoreVisible = false;
            this.showLessVisible = false;

            this.timeSlots = [];
            this.visibleTimeSlots = [];
            this.showAllTimeSlots = false;
            this.showMoreTsVisible = false;
            this.showLessTsVisible = false;

            this.location = '';
            this.date = new Date();
            this.dateByEventTimezone = null;
            this.duration = {};
            this.notes = '';
            this.description = '';
            this.internal_notes = '';

            this.setHumanReadableDate(true);
        }

        /**
         * Displays the datepicker on the UI
         */
        showDatepicker() {
            if (!this.datepickerIn) {
                angular.element('body').on('click.meet-datepicker', e => {
                    const target = angular.element(e.target);
                    if (target.is('input.date')) {
                        return true;
                    }

                    if (target.closest('.date-picker').length === 0) {
                        if (this.datepickerIn) {
                            this.hideDatepicker(e);
                        }
                    }
                });

                this.datepickerIn = true;
            }
        }

        /**
         * Disables dates that are past today and over the event expiration.
         *
         * @param {Object[]} $dates dates to be filtered
         */
        beforeRenderDatepicker($dates) {
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);

            $dates
                .filter(date => !date.current && date.utcDateValue < today.getTime())
                .forEach(date => date.selectable = false);
        }

        /**
         * Hides the datepicker from the UI
         *
         * @param {Event} e if the call comes from an event apply the scope.
         */
        hideDatepicker(e) {
            this.datepickerIn = false;
            angular.element('body').off('click.meet-datepicker');
            if (e) {
                this.$scope.$apply();
            }
        }

        /**
         * Sets a human readable time string into the input value.
         *
         * @param {Boolean} isUTC is the current date in UTC format?
         */
        setHumanReadableDate(isUTC = false) {
            const ts = this.date.getTime();
            if (!isNumber(ts)) return;

            if (isUTC) {
                // during the initialization the date comes directly from db doc in UTC format
                // moment already has the default tz set
                this.humanReadableDate = moment(ts).format('LLLL');
            } else {
                const dateObj = {
                    year: this.date.getFullYear(),
                    month: this.date.getMonth(),
                    day: this.date.getDate(),
                    hour: this.date.getHours(),
                    minute: this.date.getMinutes(),
                    second: this.date.getSeconds()
                };
                const mDate = moment(dateObj);
                this.humanReadableDate = mDate.format('LLLL');
                this.date = mDate.toDate();

                // if the date comes from the date picker we need to covert it to event tz
                this.dateByEventTimezone = moment.tz(mDate, this.event.timezone).toDate();
            }
            this.hideDatepicker();
        }

        /**
         * Checks meeting creation form validity.
         *
         * @return {Boolean} whether the form is valid or not.
         */
        validate() {
            const form = this.$scope.meetingForm;

            // Reset errors
            this.errors = {};
            form.title.$setValidity('required', true);
            form.participants.$setValidity('required', true);
            form.location.$setValidity('required', true);
            form.date.$setValidity('required', true);
            form.duration.$setValidity('required', true);

            if (this.title.trim().length === 0) {
                form.title.$setValidity('required', false);
            }

            if (this.participants.length === 0) {
                form.participants.$setValidity('required', false);
            }

            if (!(this.location || '').trim().length && !this.meeting_location) {
                form.location.$setValidity('required', false);
            }

            if (this.date.value === 0) {
                form.date.$setValidity('required', false);
            }

            if (this.duration.value === 0) {
                form.duration.$setValidity('required', false);
            }

            return form.$valid;
        }

        /**
         * Transforms collected data into valid payload for the package.
         */
        buildData() {
            // We just want an array of participants IDs.
            const participants = this.participants.filter(p => p._id || p.id).map(p => p._id || p.id);
            const externals = this.participants.filter(p => p.email).map(p => p.email);
            const params = {
                title: this.title,
                participants,
                externals,
                location: this.location,
                meeting_location: this.meeting_location,
                start_ts: (this.dateByEventTimezone || this.date).getTime() / 1000,
                duration: this.duration.value,
                description: this.description,
                internal_notes: this.internal_notes
            };

            if (this.showLegacyNotes) {
                params.notes = this.notes;
            }

            if (this.isEditMode) {
                const { meeting_data } = this.config;
                params._id = meeting_data._id;
                params._rev = meeting_data._rev;
                params.excludedParticipants = [];
                for (const pax of this.originalPaxes) {
                    if (!participants.includes(pax.id)) {
                        params.excludedParticipants.push(pax.id);
                    }
                }
                params.timestamp = meeting_data.timestamp;
            }

            return params;
        }

        /**
         * Generic saving entry point (form submitting).
         */
        saveMeeting() {
            if (this.isEditMode) {
                return this.updateMeeting();
            } else {
                return this.createMeeting();
            }
        }

        /**
         * Updates existing meeting with new data
         */
        updateMeeting() {
            this.save(this.config.actions.save.path);
        }

        /**
         * Collects and sends all data to the server to create the meeting.
         */
        createMeeting() {
            this.save(this.config.actions.send_button.path);
        }

        save(path) {
            this.validatedOnce = true;
            // Little check before going further.
            if (!this.validate()) {
                // YOU SHALL NOT PAAAASSS!!
                return;
            }

            // Calm down dude! I'm already doing my stuff...
            if (this.sending) return;
            this.sending = true;

            const params = this.buildData();

            this.progressBarService.startTask();
            this.databaseService
                .runAppScript(path, params)
                .then(({ data }) => {
                    if (data.error) {
                        this.handleSaveError(data.error);
                    }
                })
                .finally(() => {
                    this.progressBarService.finishTask();
                    this.sending = false;
                });
        }

        handleSaveError(error) {
            let parsedResponse;

            try {
                parsedResponse = JSON.parse(error);
            } catch (err) {
                return this.navService.alert({
                    title: 'general.error',
                    message: 'newmeeting_nav.create.error',
                });
            }

            const form = this.$scope.meetingForm;
            this.errors = {};
            const errors = this.errors;

            Object.keys(parsedResponse).forEach(k => {
                // Remapping the start date field.
                if (k === 'start_ts') k = 'date';
                if (form.hasOwnProperty(k)) {
                    form[k].$setValidity('required', false);
                    errors[k] = parsedResponse[k];
                }
            });
        }

        deleteMeeting($event) {
            $event.preventDefault();
            $event.stopPropagation();

            const message = i18nWithFallback(
                this.$i18n,
                'newmeeting_nav.delete_alert.message',
                'Do you want to delete this meeting?'
            );

            this.$sheet({
                title: message,
                pin: true,
                scrollable: false,
                prompt: true,
                actions: [
                    {
                        id: 'close',
                        label: this.$i18n('general.dismiss'),
                        action: sheet => sheet.reject()
                    },
                    {
                        id: 'delete',
                        label: this.$i18n('newmeeting_nav.delete_button'),
                        action: sheet => sheet.accept()
                    }
                ]
            })
                .then(() => {
                    const url = get(this, 'config.actions.send_button.path');

                    if (url) {
                        this.databaseService.runAppScript(url, {
                            id: this.config.meeting_data._id
                        });
                    }
                })
                .finally(() => {
                    this.$sheet.dismissAllSheets({
                        applyNavs: true
                    });
                });
        }

        /**
         * Used to representing the suggestion document (a person in our case).
         * @param  {Object} doc the suggested document
         * @return {String}     a string representing the person document.
         */
        representation(doc) {
            // backward compatibility for external participants which are not prospects
            if (doc.email && !doc._id && !doc.id) {
                return doc.email.trim();
            }

            if (doc.full_name) {
                return doc.full_name;
            }

            return [ doc.fname, doc.lname ].join(' ').trim();
        }

        representationSub(doc) {
            if (doc.email) {
                return this.$i18n('newmeeting_nav.external_invitee');
            }
        }

        /**
         * Handler used for when date or location change
         */
        async onDurationPaxOrDateChange() {
            if (this.validatedOnce) this.validate();

            if (!this.date || !get(this, 'duration.value') || !this.participants.length) {
                this.showLocation = false;
                this.meeting_location = null;
                return;
            }

            await this.getRoomsAvailability();
            this.showLocation = true;
        }

        /**
         * Gets the list of the rooms
         *
         * @returns {Promise<object[]>} the rooms set
         */
        async getRoomsAvailability() {
            if (!this.supportsBooking) {
                return;
            }

            try {
                this.loading = true;
                const { data: { response } } = await this.databaseService.runAppScript(this.config.actions.get_available_locations.path, {
                    meetingId: get(this.config, 'meeting_data._id'),
                    timestamp: Math.round((this.dateByEventTimezone || this.date).getTime() / 1000),
                    duration: this.duration.value,
                    capacity: this.participants.length + 1
                });

                if (response.noLocations) {
                    this.supportsBooking = false;
                    this.meeting_location = null;
                    return;
                }

                this.rooms = response.availableRooms;
                this.showMoreVisible = this.rooms.length > 6;

                if (!this.rooms.find(room => room._id === this.meeting_location)) {
                    this.meeting_location = null;
                }

                if (response.availableTimeSlots && response.availableTimeSlots.length) {
                    this.timeSlots = response.availableTimeSlots.map(t => ({
                        start: moment.unix(t.start_ts).format('LT'),
                        end: moment.unix(t.end_ts).format('LT')
                    }));

                    this.showMoreTsVisible = this.timeSlots.length > 6;
                } else {
                    this.timeSlots = [];
                }

                this.onRoomsVisibilityChange();
                this.onTimeSlotVisibilityChange();

            } catch (error) {
                console.error('[NewMeetingNavComponent] Could not load rooms availability', error);
                this.rooms = [];
            } finally {
                this.loading = false;
                this.$scope.$applyAsync();
            }
        }

        /**
         * Sets the location ID
         * @param {object} room the selected room
         */
        selectRoom(room) {
            this.meeting_location = room._id;
        }

        /**
         * Changes the rooms visibility in the UI
         */
        toggleAllRooms() {
            this.showAllRooms = !this.showAllRooms;
            this.showMoreVisible = !this.showAllRooms;
            this.showLessVisible = this.showAllRooms;
        }

        /**
         * Changes the rooms visibility in the UI
         */
        toggleAllTimeSlots() {
            this.showAllTimeSlots = !this.showAllTimeSlots;
            this.showMoreTsVisible = !this.showAllTimeSlots;
            this.showLessTsVisible = this.showAllTimeSlots;
        }

        /**
         * Handler used for changing the rooms listing
         * visibility
         */
        onRoomsVisibilityChange() {
            const visibleRooms = [ ...this.rooms ];
            if (!this.showAllRooms) {
                visibleRooms.splice(6);
            }

            this.visibleRooms = visibleRooms;
        }

        /**
         * Handler used for changing the time slots listing
         * visibility
         */
        onTimeSlotVisibilityChange() {
            const visibleSlots = [ ...this.timeSlots ];
            if (!this.showAllTimeSlots) {
                visibleSlots.splice(6);
            }

            this.visibleTimeSlots = visibleSlots;
        }

        validateExternalEmail(input = '') {
            if (!get(this.settings, 'meetings.allow-external-invitees', false)) {
                return;
            }

            if (input.match(/^[^@]+@[^@]+$/)) {
                return { email: input };
            }
            return false;
        }

        createNewParticipantLabel() {
            return i18nWithFallback(this.$i18n, 'newmeeting_nav.add_external_participant', 'Add external participant');
        }

        createNewParticipant(navService, $i18n) {
            navService.openNav({
                nav_id: 'nav_fstg',
                page_id: 'prospect',
                modal: true,
                scrollable: false,
                page_params: {},
                title: i18nWithFallback($i18n, 'newmeeting_nav.add_external_participant', 'Add external participant'),
                _parent_nav_id: 'nav_newmeeting',
            });
        }
    }
};
