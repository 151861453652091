import { extend, pluck, each, map, filter, keys, omit, isEmpty } from 'lodash';
import moment from 'moment';
import i18nWithFallback from '../utils/i18n-with-fallback';

const GET_DOC_ENDPOINT = 'webapp/doc';
const GET_VIEW_ENDPOINT = 'webapp/view';

function showEmailUsedByOtherUserAlert($sheet, $i18n) {
    $sheet({
        title: $i18n('general.logout'),
        message: i18nWithFallback(
            $i18n,
            'profile_page.email_used_by_other_user',
            'This email cannot be used.'),
        pin: false,
        scrollable: false,
        prompt: true,
        actions: [
            {
                id: 'ok',
                label: $i18n('general.ok'),
                action: sheet => sheet.reject()
            }
        ]
    });
}

/* @ngInject */
function DatabaseService(
    EID,
    PID,
    $q,
    $http,
    $i18n,
    $sheet,
    authService,
    metaService,
    handlerService,
    metricsService,
    progressBarService,
    $eventBus,
    ACTIVATED_PERSON
) {
    // save() calls have to be resolved before doing anything else
    // to ensure consistency
    let savedPromise = $q.resolve();
    const deviceTimezone = moment.tz.guess();

    const buildFormData = (params, fileParams, config) => {
        const fd = new FormData();

        fd.append('params', JSON.stringify(omit(params, ...fileParams)));
        each(fileParams, k => fd.append(k, params[k]));

        config.headers = config.headers || {};
        config.headers['Content-Type'] = undefined;
        config.transformRequest = angular.identity;
        config.data = fd;

        // adding csrf
        const csrfTokenUrl = `/api/v1/eid/${EID}/csrf-token`;
        return $http.get(csrfTokenUrl).then(result => {
            const csrfToken = result.data.token;
            fd.append('_csrf', csrfToken);
        });
    };

    const callHttp = function(config = {}) {
        if (config.params && '_cache' in config.params) {
            config.cache = config.params._cache;
            delete config.params._cache;
        }

        let prefetchParams = $q.resolve();

        if ('File' in window && config.data && config.data.params) {
            const params = config.data.params;
            const fileParams = filter(
                keys(params),
                k => params[k] instanceof File
            );
            if (fileParams.length) {
                prefetchParams = buildFormData(params, fileParams, config);
            }
        }

        return prefetchParams.then(savedPromise).then(
            () => $http(config),
            () => $http(config)
        );
    };

    const callBackend = function(endpoint, config) {
        if (!config.method) {
            config.method = 'GET';
        }

        config.url = handlerService.generateBackendUrl(endpoint);

        return callHttp(config);
    };
    const pluckValues = data => pluck(data, 'value');

    const parseAppScriptEvents = resp =>
        $q
            .all(
                map((resp.data || {}).events || [], e =>
                    $q.resolve(
                        metaService.callServiceMethod(e.service, e.method, [
                            e.params
                        ])
                    )
                )
            )
            .then(() => resp);

    return {
        docWithId(id, config = {}) {
            if (!config.params) {
                config.params = {};
            }
            config.params.id = id;
            // bypass backend cache for the user document
            if (id === PID) {
                config.params._ = Date.now();
            }
            return callBackend(
                GET_DOC_ENDPOINT,
                config
            ).then(({ data }) => data);
        },
        docsOfType(type, config = {}) {
            return callBackend(GET_VIEW_ENDPOINT, {
                method: 'POST',
                params: {
                    ddoc: 'core',
                    view: 'type'
                },
                data: extend(config, {
                    keys: [ type ],
                    include_docs: false
                })
            }).then(
                ({ data }) => pluckValues(data),
                () => []
            );
        },
        docOfTypeAndExtId(type, extId) {
            return callBackend(GET_VIEW_ENDPOINT, {
                method: 'POST',
                params: {
                    ddoc: 'core',
                    view: 'extidstype'
                },
                data: {
                    include_docs: false,
                    keys: [ [ type, extId ] ]
                }
            }).then(
                ({ data }) => pluckValues(data)[0],
                () => null
            );
        },
        docsOfTypeAndExtIds(type, extIds) {
            // short-circuit for a bug in one of couch versions which for
            // emply list of {keys} would return the whole view yikes
            if (!extIds || extIds.length === 0) {
                return $q.when([]);
            }

            let viewParams = {
                include_docs: false,
                keys: map(extIds, extId => [ type, extId ])
            };

            return callBackend(GET_VIEW_ENDPOINT, {
                method: 'POST',
                params: {
                    ddoc: 'core',
                    view: 'extidstype'
                },
                data: viewParams
            }).then(
                ({ data }) => pluckValues(data),
                () => []
            );
        },

        saveDoc(doc) {
            const save = () => {
                savedPromise = callBackend(
                    GET_DOC_ENDPOINT,
                    {
                        method: 'POST',
                        data: { doc }
                    }
                ).then(async resp => {
                    let id = resp.data.id;

                    $eventBus.emit(`doc:${resp.data.id}`);
                    $eventBus.emit(`type:${doc.fp_type}`);
                    if (id === PID) {
                        // update ACTIVATED_PERSON
                        const currentUser = await this.docWithId(PID);
                        Object.assign(ACTIVATED_PERSON, currentUser);
                        // native-isms
                        $eventBus.emit('activatedperson_updated');
                    }
                    return resp;
                }, $q.reject);

                return savedPromise;
            };

            if (doc.fp_type === 'person' && !isEmpty(doc.email) && doc.email.toLowerCase() !== ACTIVATED_PERSON.email) {
                $sheet({
                    title: $i18n('general.logout'),
                    message: i18nWithFallback(
                        $i18n,
                        'profile_page.logout_required',
                        'Changing the email address requires logout. You will be able to login again with your new email.<br/>'
                            + '<strong>Important</strong>: Please ensure that you enter the correct email address, '
                            + 'as you will need it to access the event.'),
                    pin: false,
                    scrollable: false,
                    prompt: true,
                    actions: [
                        {
                            id: 'logout',
                            label: $i18n('general.logout'),
                            action: sheet => {
                                return save()
                                    .then(() => authService.logOut({ goToWelcomePage: true }))
                                    .catch(err => {
                                        if ((err.data || {}).error === 'email_used_by_other_user') {
                                            sheet.reject();
                                            showEmailUsedByOtherUserAlert($sheet, $i18n);
                                        }
                                    });
                            }
                        },
                        {
                            id: 'cancel',
                            label: $i18n('general.cancel'),
                            action: sheet => sheet.reject()
                        }
                    ]
                });
                return $q.reject('Logout required');
            } else {
                return save();
            }
        },

        runAppScript(scriptName, params = {}) {
            const url = `/api/v1/eid/${EID}/appscripts/${scriptName}`;
            const shouldBlockInterface = params._blockInterfaceUntilFinished;
            if (shouldBlockInterface) {
                progressBarService.startBlockingTask();
                delete params._blockInterfaceUntilFinished;
            }
            const data =
                params instanceof FormData
                    ? params
                    : {
                        lang: $i18n.lang,
                        timezone: deviceTimezone,
                        session_id: metricsService?.sessionId,
                        params
                    };

            return callHttp({ method: 'POST', url, data })
                .then(parseAppScriptEvents, parseAppScriptEvents)
                .finally(() =>
                    shouldBlockInterface
                        ? progressBarService.finishBlockingTask()
                        : null
                );
        },

        /**
         * Run lifecycle scripts
         * @param {String} stage on-load
         * @return {Promise}
         */
        runLifeCycleScripts(stage) {
            if (stage !== 'on-load') {
                throw new Error(`Unsupported lifecycle stage ${stage}`);
            }

            return $http
                .get(`/api/v1/eid/${EID}/webapp/${stage}`)
                .then(({ data }) => {
                    for (const appscriptName of Object.keys(data)) {
                        const result = data[appscriptName];
                        if (result.status === 'error') {
                            console.error(
                                `Failed to run ${appscriptName}: ${data[appscriptName].error.message}`
                            );
                        }
                        parseAppScriptEvents({ data: result });
                    }
                });
        }
    };
}

// make sure JSONP is not used through $http
/* @ngInject */
function HttpJSONPInterceptor($q) {
    return {
        request(config) {
            if (config.method === 'JSONP') {
                const message = 'JSONP in $http is not allowed.';
                console.error(message, config);
                return $q.reject(message);
            }
            return config;
        }
    };
}

angular
    .module('dbinterface', [])
    .service('databaseService', DatabaseService)
    .factory('httpJSONPInterceptor', HttpJSONPInterceptor)
    .config([ '$httpProvider', $httpProvider => $httpProvider.interceptors.push('httpJSONPInterceptor') ]);
