import { isFunction, map, reject, remove, uniq } from 'lodash';

export const SuggestionsComponent = {
    bindings: {
        disabled: '<',
        documents: '=',
        ds: '<',
        error: '=',
        invalid: '<',
        exclusions: '<',
        fieldIcon: '@',
        fieldName: '@',
        placeholder: '@',
        representation: '<',
        representationSub: '<',
        autofocus: '<',
        max: '<',
        allowMissing: '<',
        createNewLabel: '<',
        createNewAction: '<',
    },
    template: require('./suggestions.pug'),
    controller: class SuggestionsComponent {
        /* @ngInject */
        constructor(
            SETTINGS,
            dataSourceEvaluatorService,
            progressBarService,
            databaseService,
            navService,
            $scope,
            $element,
            $i18n
        ) {
            this.dses = dataSourceEvaluatorService;
            this.progressBarService = progressBarService;
            this.databaseService = databaseService;
            this.$scope = $scope;
            this.exclusions = this.exclusions || [];
            this.el = $element;
            this.doc_suggestions = [];
            this.$i18n = $i18n;
            this.settings = SETTINGS;
            this.navService = navService;
        }

        $onInit() {
            console.info('[SuggestionsComponent] init');

            if (this.autofocus) {
                this.el.find('input').focus();
            }
        }

        $onDestroy() {
            console.info('[SuggestionsComponent] destroying');
            this.removeSuggestions();
        }

        /**
         * Sets default form values.
         */
        reset() {
            this.documents = [];
            this.doc_suggestions = [];
        }

        /**
         * Searches for documents and populates the suggestions.
         */
        searchDocuments() {
            this.doc_suggestions = [];
            const term = (this.doc || '').trim().toLowerCase();

            if (term.length === 0) {
                this.removeSuggestions(true);
                return;
            }

            this.checkInput(term);
        }

        checkInput(term) {
            if (isFunction(this.allowMissing)) {
                const item = this.allowMissing(term);
                if (item) {
                    this.doc_suggestions = [ item ];
                    return;
                }
            }

            this.progressBarService.startTask();
            this.dses
                .eval(this.ds, {
                    searchTerm: term
                })
                .then(resp => {
                    const docs = map(resp, row => row.doc);

                    this.doc_suggestions = uniq(docs, '_id');
                    angular.element('body').on('click.docs-sugg', e => {
                        const target = angular.element(e.target);
                        if (!target.is('.doc, input.docs')) {
                            this.removeSuggestions(true);
                        }
                    });
                    this.clearSuggestions();
                })
                .finally(() => this.progressBarService.finishTask());
        }

        /**
         * Adds a document to the seleced documents list
         * @param  {Object} doc the document object to add.
         */
        selectDoc(doc) {
            if (this.documents.length >= this.max) return;

            const valueAlreadyPresent = this.documents.some(d => {
                const { email, id, _id } = d;

                return (_id && _id === doc._id) ||
                    (id && id === doc.id) ||
                    (email && email.length && email === doc.email);
            });

            if (valueAlreadyPresent) {
                this.clearSuggestions();
                return;
            }

            this.documents.push(doc);
            this.removeSuggestions();
            document.querySelector('suggestions input').focus();
            if (this.documents.length >= this.max) {
                this.full = true;
            }
        }

        selectDocOnEnter(doc) {
            if (event.keyCode === 13) {
                this.selectDoc(doc);
            }
        }

        /**
         * Removes a document from selected documents list.
         * @param  {Object} doc the document object to remove.
         */
        removeDoc(doc) {
            remove(this.documents, doc);
            if (this.documents.length < this.max) {
                this.full = false;
            }
        }

        removeDocOnEnter(doc) {
            if (event.keyCode === 13) {
                this.removeDoc(doc);
            }
        }

        /**
         * Removes all documents already selected and the exclusion set from
         * suggested documents.
         */
        clearSuggestions() {
            const ids = map(this.documents, d => d._id || d.id) || [];
            // eslint-disable-next-line no-restricted-syntax
            this.doc_suggestions = reject(this.doc_suggestions, s => {
                return (
                    ids.indexOf(s._id || s.id) !== -1 ||
                    this.exclusions.indexOf(s._id || s.id) !== -1
                );
            });
        }

        /**
         * Removes the documents suggestions dropdown from the UI.
         *
         * @param {Boolean} digest whether to perform a UI digest or not.
         */
        removeSuggestions(digest) {
            this.doc = '';
            this.doc_suggestions = [];
            angular.element('body').off('click.docs-sugg');
            if (digest) {
                this.$scope.$apply();
            }
        }

        itemsCount() {
            return (this.documents || []).length;
        }

        onKeyUp(event) {
            if (event.keyCode === 13 && this.doc_suggestions.length) {
                event.stopPropagation();
                event.preventDefault();

                this.selectDoc(this.doc_suggestions[0]);
            }
        }

        onKeyDown(event) {
            if (event.keyCode === 13 && this.doc_suggestions.length) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }
};
