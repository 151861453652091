const GlobalTheme = /* @ngInject */ function(THEME) {
    return {
        link(_$scope, $element) {
            $element.addClass('theme-v' + THEME.version);
            $element.addClass('link-style-' + (THEME.activeLinkStyle || 'background'));

            // We hardocde some color for THEME version 2 to handle cases where those are still present in the document.
            if (THEME.version === 2) {
                THEME.backgroundColor1 = '#F7F7F7';
                THEME.backgroundColor2 = '#DDDDDD';
                THEME.backgroundColor3 = '#FFFFFF';
                THEME.foregroundColor1 = '#7D8082';
                THEME.foregroundColor2 = '#7D8082';
            }
            const darkModeEnabled = THEME.get('appearance') === 'dark';
            $element.addClass(darkModeEnabled ? 'dark-mode' : 'light-mode');
            // the dark-mode below is linked to EP-11152

            angular.element(document.head).append(`<style id="global-colors">
:root {
    --background-color-0: ${THEME.toolbar.background_color};
    --background-color-1: ${THEME.backgroundColor1};
    --background-color-2: ${THEME.backgroundColor2};
    --background-color-3: ${THEME.backgroundColor3};
    --background-color-4: ${THEME.backgroundColor4 || '#f7f7f7'};
    --background-color-5: ${THEME.backgroundColor5};
    --contrast-color: ${THEME.contrastColor || THEME.toolbar.background_color || '#2c3e50'};
    --foreground-color-0: ${THEME.toolbar.font_color};
    --foreground-color-1: ${THEME.foregroundColor1};
    --foreground-color-2: ${THEME.foregroundColor2};
}</style>`);

            if (THEME.version !== 3) {
                return;
            }

            angular.element(document.head).append(`<style id="global-theme">

body {
    background-color: ${THEME.backgroundColor4};
    color: ${THEME.foregroundColor1};
}

app-nav-bar .wrapper {
    color: ${THEME.foregroundColor1};
    background-color: ${THEME.backgroundColor3};
}

body .panel,
body .panel.panel-default,
body .card-block,
body update-browser-box .content,
.platform-web .fstg-block .panel.panel-default
{
    background-color: ${THEME.backgroundColor3};
    color: ${THEME.foregroundColor1};
    border-color: ${THEME.backgroundColor2};
}

.dark-mode .block_common_edit_text .panel.panel-default {
    border-color: #484D51;
}

select, .select .options, .select .select-wrapper {
    background-color: ${THEME.backgroundColor3};
    color: ${THEME.foregroundColor1};
    border-color: ${THEME.backgroundColor2};
}

.select .options .option:hover {
    background-color: ${THEME.backgroundColor2};
}

body update-browser-box .browser {
    background-color: ${THEME.backgroundColor1};
}

body .panel.panel-default .extras {
    color: ${THEME.foregroundColor2};
}

.panel-default > .panel-heading {
    color: ${THEME.foregroundColor1};
    border-color: ${THEME.backgroundColor2};
}

.panel-footer {
    background-color: ${THEME.backgroundColor1};
    color: ${THEME.foregroundColor1};
}

.card-block .widget > div {
    color: ${THEME.foregroundColor1};
}

.row-separator {
    background-color: ${THEME.backgroundColor3};
}

.nav-header {
    color: ${THEME.foregroundColor2};
}

.nav-spotman {
    color: ${THEME.foregroundColor1};
}

.nav-list-item-subsubtitle {
    color: ${THEME.foregroundColor2};
}

.nav-list-item-title {
    color: ${THEME.foregroundColor1};
}

master-row .nav-list-item {
    background-color: ${THEME.backgroundColor1};
}

.nav-list-item-subtitle {
    color: ${THEME.foregroundColor2};
}

input,
.dark-mode input:disabled,
textarea,
.form-control,
form.vibram .form-control,
form.vibram .form-control[disabled],
form.vibram .form-control[readonly]
{
    color: ${THEME.foregroundColor1};
    background-color: ${THEME.backgroundColor3};
    border-color: ${THEME.backgroundColor2};
}

.dark-mode input,
.dark-mode textarea,
.dark-mode .form-control,
.dark-mode form.vibram .form-control,
.dark-mode form.vibram .form-control[disabled],
.dark-mode form.vibram .form-control[readonly]{
    border-color: #484D51;
}

filtering h5,
filtering .popover-footer
{
    border-color: ${THEME.backgroundColor3};
}

filtering .popover-footer {
    background-color: transparent;
}

filtering .tags .checkbox label {
    background-color: transparent;
    border-color: ${THEME.contrastColor};
}

filtering .tags .checkbox label:hover {
    background-color: ${THEME.backgroundColor3};
}

filtering .tags .checkbox label .text {
    color: ${THEME.contrastColor};
}

.modal-content {
    background-color: ${THEME.backgroundColor3};
}

.modal-footer .btn.btn-default,
.modal-footer,
.modal-header
{
    border-color: ${THEME.backgroundColor2};
}

inline-block-paginator.panel-footer {
    border-color: ${THEME.backgroundColor2};
}

.nav-note .parent-preview,
.nav-list.nav-content
{
    background-color: ${THEME.backgroundColor3};
    border-color: ${THEME.backgroundColor2};
}

.list-group-item {
    background-color: ${THEME.backgroundColor3};
    border-color: ${THEME.backgroundColor2};
}

.link-style-underline app-nav-bar .wrapper .item.active.inverse-bg-when-active {
    border-bottom: solid 4px ${THEME.contrastColor};
    background-color: transparent !important;
    border-radius: 0;
    margin-bottom: 0;
    padding-bottom: 6px;
}

.theme-v3 profile-row,
#pip-wrapper video-call .onboarding,
#pip-wrapper video-call > .video-wrapper {
    border-color: ${THEME.backgroundColor2};
}

.theme-v3 profile-row .user-info img {
    border-color: ${THEME.backgroundColor3};
}

.theme-v3 profile-row .bg-wrapper {
    box-shadow: inset 0 60px ${THEME.contrastColor};
}

.theme-v3 .bg-wrapper {
    background-color: ${THEME.backgroundColor3};
    color: ${THEME.foregroundColor1};
}

.theme-v3 .bg-wrapper .banner {
    background-color: ${THEME.backgroundColor3};
}

.theme-v3 .bg-wrapper .user-info .nav-list-item-title {
    color: ${THEME.foregroundColor1};
}

.theme-v3 .bg-wrapper .user-info .nav-list-item-subtitle {
    color: ${THEME.foregroundColor2};
}

.block_feed_comment .toolbar-actions .footer-post-actions-block  {
    border-color: ${THEME.backgroundColor2};
}

.block_feed_topic .footer-post-actions-block,
.block_feed_topic .panel-body a
{
    color: ${THEME.contrastColor};
}

.block_feed_topic .footer-post-actions-block {
    border-color: ${THEME.backgroundColor2};
}

feed-reply-block {
    color: ${THEME.foregroundColor2};
    border-color: ${THEME.backgroundColor2};
}

feed-post-attachments .attachment,
feed-post-attachments .attachment .media {
    background-color: ${THEME.backgroundColor2};
}

agenda-nav .nav-content {
    color: ${THEME.foregroundColor1};
    background-color: ${THEME.backgroundColor3};
}

agenda-nav .nav-content,
agenda-calendar,
agenda-calendar .item,
.nav-list-item
{
    border-color: ${THEME.backgroundColor2};
}

.btn.btn-default:hover, .active-bg-highlight:hover,
.nav-spotman .nav-list-item:hover {
    background-color: ${THEME.backgroundColor5};
}

agenda-calendar .item.disabled {
    color: ${THEME.foregroundColor2};
}

agenda-calendar .item.selected {
    background-color: ${THEME.backgroundColor1};
}

.nav-scan .nav-content,
agenda-nav .nav-sidebar .search
{
    color: ${THEME.foregroundColor1};
    background-color: ${THEME.backgroundColor3};
    border-color: ${THEME.backgroundColor2};
}

.nav-scan .nav-content {
    border: 1px solid ${THEME.backgroundColor2};
}

.nav-note .editor {
    background-color: ${THEME.backgroundColor1};
    border-color: ${THEME.backgroundColor2};
    color: ${THEME.foregroundColor1};
}

body .ql-editor.ql-blank::before {
    color: ${THEME.foregroundColor2};
}

.suggestions .documents,
.suggestions .new-meeting .date-picker,
.new-meeting .suggestions .date-picker,
.modal.modal-sheet.menu .modal-footer .btn-group.btn-group-justified .btn-group .btn
{
    background-color: ${THEME.backgroundColor1};
    border-color: ${THEME.backgroundColor2};
    color: ${THEME.foregroundColor1};
}

.modal.modal-sheet.menu .modal-footer .btn-group.btn-group-justified .btn-group .btn.btn-default:hover,
.suggestions .documents .doc:hover
{
    background-color: ${THEME.backgroundColor5} !important;
    color: ${THEME.foregroundColor1} !important;
}

conversation-nav .nav-content {
    background-color: ${THEME.backgroundColor3};
    border-color: ${THEME.backgroundColor2} !important;;
}

conversation-nav .nav-footer {
    background-color: ${THEME.backgroundColor2};
    border-color: ${THEME.backgroundColor2};
}

.conversation-row.own .message {
    background-color: ${THEME.backgroundColor1};
    color: ${THEME.foregroundColor1};
}

.conversation-row .message {
    background-color: ${THEME.backgroundColor2};
}

.conversation-row .name {
    color: ${THEME.foregroundColor1};
}

chat-details, .chat-details, .invites {
    background-color: ${THEME.backgroundColor4};
}

.chat-details .action a {
    color: ${THEME.foregroundColor2};
}

.chat-details .action.highlight a {
    color: ${THEME.contrastColor};
}

.chat-details .action,
.chat-details h3
{
    border-color: ${THEME.backgroundColor2};
}

.nav-document-pdf-toolbar {
    background-color: ${THEME.backgroundColor1};
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: ${THEME.contrastColor};
}

.ql-snow .ql-stroke {
    stroke: ${THEME.contrastColor};
}

div[lazy-background-asset-src].asset-loaded,
div[background-asset-src].asset-loaded {
    background-color: transparent;
}

.new-meeting .suggestions .documents,
.suggestions .new-meeting .documents,
.new-meeting .date-picker {
  background-color: ${THEME.backgroundColor3};
  border-color: ${THEME.backgroundColor2};
}

.datetimepicker .disabled,
.datetimepicker .disabled:hover {
  color: ${THEME.foregroundColor2};
}

.datetimepicker .minute:hover,
.datetimepicker .hour:hover,
.datetimepicker .day:hover,
.datetimepicker .switch:hover,
.datetimepicker .left:hover,
.datetimepicker .right:hover {
  background-color: ${THEME.backgroundColor5};
}

.datetimepicker .current,
.datetimepicker .current:hover,
.datetimepicker .current.disabled,
.datetimepicker .current.disabled:hover {
  background-color: ${THEME.backgroundColor2};
}

.datetimepicker .active,
.datetimepicker .active:hover,
.datetimepicker .active.disabled,
.datetimepicker .active.disabled:hover,
.datetimepicker .table > tbody > tr > td.active,
.datetimepicker .table > tbody > tr > td.active.disabled,
.datetimepicker .table > tbody > tr > td.active.disabled:hover {
  background-color: ${THEME.contrastColor};
  color: ${THEME.foregroundColor1};
}

.block-meeting {
  background-color: ${THEME.backgroundColor3};
}

.block-meeting section {
  color: ${THEME.foregroundColor2};
}

.block-meeting section .pax-list span {
  border-color: ${THEME.backgroundColor1};
}

.block-meeting section .pax-list span.initials {
  color: ${THEME.foregroundColor1};
  background-color: ${THEME.backgroundColor2};
}

.nav-votecomment .commenting textarea {
  border-color: ${THEME.backgroundColor1};
}
</style>`);
        }
    };
};

angular.module('maestro.directives')
    .directive('globalTheme', GlobalTheme);
