import {
    extend,
    last,
    uniqueId,
    cloneDeep,
    findIndex,
    merge,
    uniq
} from 'lodash';

// INTERNALS

// takes a nav_id, nav or a {nav_id, _dynamic_nav, params} and returns a full nav
function importNav(config) {
    if (typeof config === 'string') {
        config = {
            nav_id: config
        };
    }
    const navId = config._id || config.nav_id;
    let nav = merge(
        {
            _id: navId || '_dynamic'
        },
        navId ? this.EVENT_NAVS[navId] : null,
        config
    );
    delete nav.nav_id;
    return nav;
}

function initNav(nav) {
    // special case for the home, which is dynamic
    if (nav.$is_home) {
        nav = this.HOME_NAV;
    }
    nav = importNav.call(this, nav);
    if (!nav) {
        throw new Error('Cannot initialize a null nav');
    }
    return angular.merge(
        {},
        {
            nuid: uniqueId(`${nav._id}_${Date.now()}_`)
        },
        // always do a copy of the nav to be sure that the reference is updated
        angular.copy(nav)
    );
}

function openModalNav(nav) {
    const title = nav.show_title === false ? '' : nav.title;
    const scrollable = nav.scrollable !== false;
    nav.show_title = false;
    const sheet = this.$sheet({
        nav,
        title,
        templateUri: this.NAV_TEMPLATES[nav.nav_type],
        pin: scrollable ? 'bottom' : '',
        scrollable,
        actions: [
            {
                id: 'close',
                label: this.$i18n('general.close'),
                action: sheet => {
                    const navIndex = findIndex(
                        this.savedNavSheets,
                        sheet => sheet.nuid === nav.nuid
                    );

                    if (navIndex !== -1) {
                        this.savedNavSheets.splice(navIndex, 1);
                    }

                    return sheet.accept();
                }
            }
        ]
    });

    const navCopy = cloneDeep(nav);
    // copy sheetId for reconciliation
    navCopy.sheetId = sheet.$$sheetId;
    nav.sheetId = navCopy.sheetId;
    this.savedNavSheets.push(navCopy);

    if (nav.route && nav.set_route !== false) {
        // performe navigation to update location properly
        const backIfNotNav = reason => {
            if (!reason || !reason.applyNavs) this.$window.history.back();
        };
        sheet.then(backIfNotNav, backIfNotNav);
    }
}

function activateNav(nav, skipSetRoute) {
    this.$sheet.dismissMenus();

    if (!skipSetRoute && nav.set_route !== false) {
        this.navRouterService.setUrlForNav(nav, this);
    }

    if (nav.events && nav.events.on_appear) {
        this.databaseService.runAppScript(nav.events.on_appear.path);
    }

    this.emit('$navChange', nav);

    trackNav.call(this, nav);
}

function trackNav(nav) {

    this.metricsService.trackAnalytics({
        name: 'view',
        document_type: 'nav',
        // TODO: remove me, this is backwards compatibility
        fp_type: 'nav',
        document_id: nav._id
    });

    const trackParams = {
        id: nav._id
    };
    if (trackParams.id === 'nav_fstg') {
        const page_params = nav.page_params;
        trackParams.document_type = page_params.fp_type;
        trackParams.document_id = page_params.model_id;
    }

    // add extra tracking params
    trackParams.connection_mode = window.navigator.onLine;

    if (nav.analytic_params) {
        extend(trackParams, nav.analytic_params);
    }
}

export default class NavOps {
    /* @ngInject */
    constructor(
        EVENT_NAVS,
        NAV_TEMPLATES,
        HOME_NAV,

        $sheet,
        $window,
        $i18n,
        $eventBus,

        navRouterService,
        metricsService,
        databaseService,
        liveStreamService
    ) {
        // NOTE Constructor is never called because navService is built by utils/mixin
        this.EVENT_NAVS = EVENT_NAVS;
        this.HOME_NAV = HOME_NAV;
        this.NAV_TEMPLATES = NAV_TEMPLATES;

        this.$sheet = $sheet;
        this.$i18n = $i18n;
        this.$window = $window;
        this.$eventBus = $eventBus;

        this.navRouterService = navRouterService;
        this.metricsService = metricsService;
        this.databaseService = databaseService;
        this.liveStreamService = liveStreamService;

        // local state
        // activeNav is an object containing the nav to keep the reference around
        this.activeNav = {
            nav: null
        };
        this.savedNavSheets = [];
    }

    // API

    openNav(nav) {
        if (!nav) return;

        this.$eventBus.emit('theatreMode:off');

        const newNav = initNav.call(this, nav);
        if (this.$sheet.hasOpenSheets()) {
            newNav.modal = true;
        }

        if (newNav.modal) {
            openModalNav.call(this, newNav);
        } else {
            this.activeNav.nav = newNav;
        }

        activateNav.call(this, newNav);
    }

    presentNav(nav) {
        if (!nav) return;

        this.$eventBus.emit('theatreMode:off');

        const fullNav = initNav.call(this, nav);
        if (fullNav.modal) {
            return this.openNav(nav);
        }

        // cleanup sheets
        this.$sheet.dismissAllSheets({
            applyNavs: true
        });

        this.activeNav.nav = fullNav;
        activateNav.call(this, fullNav);
    }

    showNav(nav) {
        console.warn(
            '[navService/showNav] this method is being depracated, use presentNav instead'
        );
        this.presentNav(nav);
    }

    showHome(params = {}) {
        if (!params.force) {
            return console.warn(
                '[navService/showHome] force: false, ignoring request (iOS compatibility)'
            );
        }

        this.resetNavigation();
    }

    hideHome() {
        // does nothing now as the home is never kept
    }

    resetNavigation() {
        this.presentNav(this.HOME_NAV);
    }

    closeFullscreenNav() {
        if (this.$sheet.hasOpenSheets()) {
            this.$sheet.dismissSheet(this.getLastSheetId());
        }
    }

    closeLastNav() {
        if (this.$sheet.hasOpenSheets()) {
            const lastSheetId = this.getLastSheetId();
            if (lastSheetId) return this.$sheet.dismissSheet(lastSheetId);
        }

        this.$window.history.go(-1);
        return false;
    }

    removeSavedSheet(sheetToRemoveId) {
        this.savedNavSheets = this.savedNavSheets.filter(
            sheet => sheet.sheetId !== sheetToRemoveId
        );
    }

    getSavedSheets() {
        this.savedNavSheets = this.savedNavSheets
            .filter(sheet => this.$sheet.getSheetIds().includes(sheet.sheetId));

        return this.savedNavSheets;
    }

    getLastSheetId() {
        return last(this.$sheet.getNavSheetIds());
    }

    startPictureInPicturePreview(params) {
        if (params.id) {
            console.log('[navService/startPictureInPicturePreview] Starting a live stream with params', params);
            return this.liveStreamService.start(params);
        }
        this.$eventBus.emit('pip:start', params);
    }

    applyNavs(newNav, newSheets) {
        this.$eventBus.emit('theatreMode:off');

        // initialize provided activeNav
        newNav = initNav.call(this, newNav);
        this.activeNav.nav = newNav;
        // do not set route on this case, as this is coming from a route already
        activateNav.call(this, newNav, true);

        // reconciliate nav sheets with existing sheets
        // filter open sheets with the one defined in the state
        const existingSheetIds = this.$sheet.getSheetIds();
        const newSheetsIds = [];
        this.savedNavSheets.splice(
            0,
            this.savedNavSheets.length,
            ...newSheets.map(nav => {
                if (existingSheetIds.indexOf(nav.sheetId) === -1) {
                    // this nav does not have a corresponding sheet
                    nav = initNav.call(this, nav);
                    openModalNav.call(this, nav);
                }
                newSheetsIds.push(nav.sheetId);
                return nav;
            })
        );

        this.savedNavSheets = uniq(this.savedNavSheets, x => x.nuid);
        // clean sheets that should not be opened anymore
        this.$sheet.getNavSheetIds().forEach(sheetId => {
            if (newSheetsIds.indexOf(sheetId) === -1) {
                this.$sheet.dismissSheet(sheetId, {
                    applyNavs: true
                });
            }
        });

        // clean menus. They should be popover and not sheet?
        this.$sheet.dismissMenus();
    }

    getActiveNav() {
        return this.activeNav.nav;
    }
}
