import i18nWithFallback from '../../../utils/i18n-with-fallback';

export function getLabels($i18n, ACTIVATED_PERSON) {
    return {
        countdown: {
            title: i18nWithFallback($i18n, 'live-stream.countdown.status_message', 'Hold tight!'),
            countdownMessage: i18nWithFallback($i18n, 'live-stream.countdown.intro_message', 'The live stream will start in:'),
            countdownEndedMessage: i18nWithFallback($i18n, 'live-stream.countdown.countdown_ended', 'The wait is over, we are about to start…'),
            streamEnded: i18nWithFallback($i18n, 'live-stream.countdown.replay_pending_message', 'This stream has ended. Thank you for watching!'),
            day: i18nWithFallback($i18n, 'live-stream.countdown.day', 'day'),
            days: i18nWithFallback($i18n, 'live-stream.countdown.days', 'days')
        },
        polls: {
            waitingPollResults: i18nWithFallback($i18n, 'live-stream.polling.loading', 'Thanks for responding')
        },
        claps: {
            throttleMessage: i18nWithFallback($i18n, 'reactions.throttling.claps', "Give your hands a rest. In a minute you'll be able to clap again."),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.claps', 'Clap')
        },
        qna: {
            answerNow: i18nWithFallback($i18n, 'qna.answering_now', 'Answering now'),
            anonymous: i18nWithFallback($i18n, 'qna.input.anonymous', 'Anonymous')
        },
        'client-fullscreen': {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.fullscreen', 'Toggle fullscreen'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.fullscreen', 'Toggle fullscreen')
        },
        fullscreen: {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.fullscreen', 'Toggle fullscreen'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.fullscreen', 'Toggle fullscreen')
        },
        'play-pause-toggle': {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.playpause', 'Play/pause'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.play_and_pause', 'Play or pause')
        },
        'play-stop-toggle': {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.playstop', 'Play/stop'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.play_and_stop', 'Play or stop')
        },
        help: {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.help', 'Having an issue?'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.help', 'Help')
        },
        'seek-bar': {
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.progress', 'Progress')
        },
        'sidebar-toggle': {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.sidebar', 'Open/close side panel'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.sidebar', 'Open or close side panel')
        },
        language: {
            title: i18nWithFallback($i18n, 'player.controls.language.title', 'Subtitles/CC'),
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.language', 'Change language'),
            tooltip_enable: i18nWithFallback($i18n, 'live-stream.tooltip.enable_cc', 'Enable subtitles/CC'),
            tooltip_disable: i18nWithFallback($i18n, 'live-stream.tooltip.disable_cc', 'Disable subtitles/CC'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.captions', 'Captions')
        },
        interprefy: {
            tooltip: i18nWithFallback($i18n, 'live-stream.tooltip.interprefy', 'Change language'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.interprefy', 'Change language')
        },
        'live-badge': {
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.live', 'This video is a live stream')
        },
        'volume': {
            buttonAriaLabel: i18nWithFallback($i18n, 'a11y.player.controls.mute_and_unmute', 'Mute or unmute'),
            sliderAriaLabel: i18nWithFallback($i18n, 'a11y.player.controls.volume', 'Volume level')
        },
        'time': {
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.elapsed_time', 'Elapsed time')
        },
        'side-bar': {
            stream: {
                tabName: i18nWithFallback($i18n, 'stream.stream_tab', 'Stream'),
                streamEnableTitle: i18nWithFallback($i18n, 'stream.stream_enable.title', 'Watch the stream'),
                streamEnableSubtitle: i18nWithFallback($i18n, 'stream.stream_enable.subtitle', 'As an in-person attendee, the stream isn\'t available by default. Enable it using the button below.'),
                streamEnableButton: i18nWithFallback($i18n, 'stream.stream_enable.button', 'Watch stream'),
                streamDisableTitle: i18nWithFallback($i18n, 'stream.stream_disable.title', 'Hide stream'),
                streamDisableSubtitle: i18nWithFallback($i18n, 'stream.stream_disable.subtitle', 'By hiding the live stream, we eliminate the small delay, enabling interactivity to be displayed in real time.'),
                streamDisableButton: i18nWithFallback($i18n, 'stream.stream_disable.button', 'Hide stream')
            },
            viewers: {
                tabName: i18nWithFallback($i18n, 'viewers.title', 'Viewers'),
                waitingForViewers: i18nWithFallback($i18n, 'viewers.empty', 'Waiting for participants')
            },
            qna: {
                private: {
                    tabName: i18nWithFallback($i18n, 'qna.private_title', 'Private Q&A'),
                    noQuestionTitle: i18nWithFallback($i18n, 'qna.private_empty_title', 'Ask a question to today’s speaker(s)!'),
                    askFirst: i18nWithFallback($i18n, 'qna.private_empty', 'Hi {{fname}}, questions during this session will only be visible to moderators and speakers!', ACTIVATED_PERSON),
                    postNotification: i18nWithFallback($i18n, 'qna.private_input_posted', 'Your question has been sent to the moderator and will not appear on this panel.')
                },
                tabName: i18nWithFallback($i18n, 'qna.title', 'Q&A'),
                noQuestionTitle: i18nWithFallback($i18n, 'qna.empty_title', 'There are no questions yet'),
                askFirst: i18nWithFallback($i18n, 'qna.empty', "Hi {{fname}}, be first to ask a question to today's speaker(s)!", ACTIVATED_PERSON),
                send: i18nWithFallback($i18n, 'general.send', 'send'),
                qnaLimitNotification: i18nWithFallback($i18n, 'qna.input.qna_limit', 'You have reached the limit of questions you can send per minute. You will be able to send a new question in {0} seconds'),
                postNotification: i18nWithFallback($i18n, 'qna.input.posted', 'Your question will appear in the feed once it is approved by today’s host.'),
                sendAnonymously: i18nWithFallback($i18n, 'votecomment_nav.send_anonymously', 'Anonymous'),
                inputPlaceholder: i18nWithFallback($i18n, 'qna.input.hint', 'Write your question...'),
                anonymous: i18nWithFallback($i18n, 'qna.input.anonymous', 'Anonymous'),
                answerNow: i18nWithFallback($i18n, 'qna.answering_now', 'Answering now'),
                sortOptions: {
                    newest: i18nWithFallback($i18n, 'qna.sort_options.newest', 'Time'),
                    upvotes: i18nWithFallback($i18n, 'qna.sort_options.upvotes', 'Upvotes')
                },
                orientationOptions: {
                    newest: {
                        bottomToTop: i18nWithFallback($i18n, 'qna.sort_direction.newest_bottom', 'Most recent bottom'),
                        topToBottom: i18nWithFallback($i18n, 'qna.sort_direction.newest_top', 'Most recent top')
                    }
                }
            },
            polls: {
                tabName: i18nWithFallback($i18n, 'live-stream.polling.title', 'Poll'),
                submit: i18nWithFallback($i18n, 'live-stream.polling.submit', 'Send'),
                noPollTitle: i18nWithFallback($i18n, 'live-stream.polling.no_polling_title', 'There is not a running poll yet.'),
                noPollSubtitle: i18nWithFallback($i18n, 'live-stream.polling.no_polling_subtitle', "You'll get a notification when a poll is launched."),
                pollIncomingTitle: i18nWithFallback($i18n, 'live-stream.polling.poll_incoming_title', 'A poll will be launched here soon!'),
                pollIncomingSubtitle: i18nWithFallback($i18n, 'live-stream.polling.poll_incoming_subtitle', 'The host of this session launched a poll. It will appear here soon for your participation.'),
                thankYouForVoting: i18nWithFallback($i18n, 'live-stream.polling.loading', 'Thanks for responding'),
                answerLimitHint: i18nWithFallback($i18n, 'live-stream.polling.answer_limit_hint', 'Allowed answers: {0}'),
                seeResults: i18nWithFallback($i18n, 'live-stream.polling.alert.results', 'See results'),
                goToPoll: i18nWithFallback($i18n, 'live-stream.polling.alert.open', 'Go to poll'),
                joinPoll: i18nWithFallback($i18n, 'live-stream.polling.alert.join', 'Join poll'),
                send: i18nWithFallback($i18n, 'general.send', 'send'),
                inputPlaceholder: i18nWithFallback($i18n, 'live-stream.polling.wordcloud.input_placeholder', 'Your answer'),
                thankYouForAnswer: i18nWithFallback($i18n, 'live-stream.polling.alert.thank_you_for_answer', 'Thank you for your answer'),
                anErrorOccurred: i18nWithFallback($i18n, 'live-stream.polling.alert.an_error_occurred', 'An Error occurred, please try again'),
                alreadyVoted: i18nWithFallback($i18n, 'live-stream.polling.alert.already_voted', 'You already voted for this poll'),
                alreadySubmittedAnswer: i18nWithFallback($i18n, 'live-stream.polling.alert.already_submitted_answer', 'We already saved this answer for you'),
                youCannotSubmitMore: i18nWithFallback($i18n, 'live-stream.polling.alert.you_cannot_submit_more', 'Thank you for taking part in this poll! You cannot submit any further answers.'),
                youCannotSubmitMultipleWords: i18nWithFallback($i18n, 'live-stream.polling.alert.you_cannot_submit_multiple_words', 'You cannot submit multiple words'),
                blockedWords: i18nWithFallback($i18n, 'live-stream.polling.alert.no_blocked_words', 'Sorry, it seems like that phrase is not allowed'),
                charLimitExceeded: i18nWithFallback($i18n, 'live-stream.polling.alert.char_limit_exceeded', 'You have exceeded the maximum number of characters allowed'),
                youCanSubmitMore: i18nWithFallback($i18n, 'live-stream.polling.alert.you_can_submit_more', 'Thanks for your answer, you can submit other answers'),
                multipleWordAnswerTooltip: i18nWithFallback($i18n, 'live-stream.polling.alert.multiple_word_answer_tooltip', 'This will be considered as one answer'),
                expandSidebar: i18nWithFallback($i18n, 'live-stream.polling.wordcloud.expand_sidebar', 'Expand view')
            },
            translations: {
                tabName: i18nWithFallback($i18n, 'live-stream.translations_tab.tab_name', 'Translation'),
                header: i18nWithFallback($i18n, 'live-stream.translations_tab.header', 'Translations & Captions'),
            }
        },
        resolution: {
            title: i18nWithFallback($i18n, 'player.controls.resolution.title', 'Resolution'),
            tooltip: i18nWithFallback($i18n, 'player.controls.resolution.tooltip', 'Change Resolution'),
            ariaLabel: i18nWithFallback($i18n, 'a11y.player.controls.resolution', 'Change Resolution')
        },
        'crash-overlay': {
            title: i18nWithFallback($i18n, 'live-stream.error.player-title', 'Oops! Something went wrong.'),
            message: i18nWithFallback(
                $i18n,
                'live-stream.error.player-message',
                'Please refresh the browser or refer to our troubleshooting article for guidance.',
            ),
            buttonLabel: i18nWithFallback($i18n, 'live-stream.error.player-button', 'Refresh')
        },
    };
}
