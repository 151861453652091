import { Tab } from './Tab';

export class Stream extends Tab {

    get config() {
        return {
            id: 'stream'
        };
    }

    onSideBarEvent(payload) {
        const eid = this.services.EVENT._id;
        const currentKey = this.services.liveStreamService.STREAM_TOGGLE_KEY.replace('{{eid}}', eid);

        console.info('[Sidebar-Stream] Toggling stream visualization', payload);

        localStorage.setItem(currentKey, JSON.stringify(payload));

        this.services.eventBus.emit('pip:reset');
    }
}
